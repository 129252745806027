import React, { useState } from "react";
import { Link } from "react-router-dom";
import portfolio from "./img/porfolio.svg";
import Footer from "./Footer";
import book from "./img/book.jpeg";

function LoveNote() {
  return (
    <div className="flex flex-col w-full min-h-screen overflow-hidden font-sans">
      <nav
        className={
          "sticky flex justify-center align-middle w-full py-3 lg:py-5 mx-auto shadow-xl shadow-gray-100"
        }
      >
        <Link to="/">
          <div className="relative  flex items-center text-lg font-serif font-bold py-2">
            Ana D.
          </div>
        </Link>
      </nav>
      <div className="flex sm:px-4 lg:px-44 md:px-20 px-10 flex-col py-20 h-full w-full overflow-hidden text-left grow">
        <h1 className="text-5xl font-medium text-dark sm:text-5xl md:text-5xl">
          LoveNote - User Experience Research
        </h1>
        <p className=" leading-relaxed mb-1 mt-6 font-light text-gray-800  md:text-lg lg:text-lg">
          <b className="font-bold">Description: </b> LoveNote is an education
          startup app working towards finding easier ways for users to learn
          languages. I aggregated information for translation between English,
          Spanish, and Twi. I also conducted research and data analysis to
          determine how people learn and process new languages.
        </p>

        <p className=" leading-relaxed mb-6 mt-2 font-light text-gray-800 lg:mb-8 md:text-lg lg:text-lg">
          <b className="font-bold">Role: </b> UX Researcher
        </p>

        <p className=" leading-relaxed mb-6 mt-2 font-light text-gray-800 lg:mb-8 md:text-lg lg:text-lg">
          <b className="font-bold">Website Link: </b>{" "}
          <a
            className="text-blue-600"
            target="_blank"
            href="https://golovenote.me/"
          >
            https://golovenote.me/
          </a>
        </p>

        <img src={book} alt="header image" className="w-full mb-10" />

        <hr></hr>

        <div className="grid max-w-screen-lg px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <div className="relative inline-flex overflow-hidden w-80 h-100">
              <img
                src={portfolio}
                alt="Ana Diaz-Ramos"
                className="self-center drop-shadow-2xl object-cover"
              />
            </div>
          </div>
          <div className="mr-auto place-self-center mt-10 lg:col-span-7">
            <h1 className="text-5xl font-display font-medium tracking-tight text-dark sm:text-5xl md:text-5xl">
              Ana Diaz-Ramos
            </h1>
            <p className="max-w-2xl leading-relaxed mb-6 mt-2 font-light text-gray-800 lg:mb-8 md:text-lg lg:text-lg">
              A passionate marketer with the goal to help bring products and
              services to those in need and drive business impact.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default LoveNote;
