import "./App.css";
import Landing from "./Landing";
import Quartal from "./Quartal";
import Airfryer from "./Airfryer";
import LoveNote from "./LoveNote";
import RoadSide from "./RoadSide";

import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/quartal" element={<Quartal />} />
        <Route path="/airfryer" element={<Airfryer />} />
        <Route path="/lovenote" element={<LoveNote />} />
        <Route path="/roadside" element={<RoadSide />} />
      </Routes>
    </div>
  );
}

export default App;
