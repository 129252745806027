import React, { useState } from "react";
import { Link } from "react-router-dom";
import portfolio from "./img/porfolio.svg";
import piano from "./img/piano.jpeg";
import airfryer from "./img/airfryer.png";
import book from "./img/book.jpeg";
import car from "./img/car.jpeg";

import Footer from "./Footer";

function Landing() {
  return (
    <div className="flex flex-col w-full min-h-screen overflow-hidden font-sans">
      <nav
        className={
          "sticky flex justify-center align-middle w-full py-3 lg:py-5 mx-auto shadow-sm shadow-gray-100"
        }
      >
        <Link to="/">
          <div className="relative  flex items-center text-lg font-serif font-bold py-2">
            Ana D.
          </div>
        </Link>
      </nav>
      <div className="flex flex-col h-full min-h-[60vh] shadow-xl shadow-slate-100 items-center justify-center w-full overflow-hidden grow">
        <div className="flex items-center justify-center w-full px-14 -my-10 overflow-hidden h-screen--80 grow sm:px-6 lg:px-44 md:px-20">
          <div className="sm:text-left lg:text-left lg:w-1/2 md:w-3/4">
            <h1 className="text-7xl font-display font-medium tracking-tight text-dark sm:text-5xl md:text-7xl">
              <span className="block xl:inline">Ana</span>{" "}
              <span className="block xl:inline">Diaz-Ramos</span>{" "}
            </h1>
            <p className="mt-3 leading-relaxed text-gray-800 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0">
              A passionate marketer with the goal to help bring products and
              services to those in need and drive business impact.
            </p>
          </div>

          <div className="relative hidden w-80 h-84 ml-40 md:block">
            <img
              className="self-center  object-cover"
              src={portfolio}
              alt="portfolio"
            />
          </div>
        </div>
      </div>
      <div className="flex sm:px-4 lg:px-44 md:px-20 px-10 flex-col py-20 h-full w-full overflow-hidden text-left grow">
        <section className="text-gray-600 body-font">
          <div className="container px-5 py-4 mx-auto">
            <div className="flex flex-wrap -m-4">
              <div className="p-4 md:w-1/2">
                <div className="h-full rounded-xl shadow-cla-blue bg-gradient-to-r from-indigo-50 to-blue-50 overflow-hidden">
                  <img
                    className="lg:h-48 md:h-36 w-full object-cover object-center scale-110 transition-all duration-400 hover:scale-100"
                    src={piano}
                    alt="blog"
                  />
                  <div className="p-6">
                    <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                      Digital Marketing
                    </h2>
                    <h1 className="title-font text-lg font-medium text-gray-600 mb-3">
                      Quartal Digital Marketing Development
                    </h1>
                    <p className="leading-relaxed mb-3">
                      Quartal is a music education startup company that makes
                      music easier than ever to learn and teach music. I work in
                      the area of digital marketing development while using
                      growth marketing strategies to promote brand awareness on
                      social media and turn viewership into profit.
                    </p>
                    <div className="flex items-center flex-wrap ">
                      <Link
                        to="/quartal"
                        className="text-white font-medium text-sm bg-gradient-to-r from-fuchsia-300 to-pink-400 hover:scale-105  shadow-cla-blue px-5 py-2 rounded-3xl"
                      >
                        Learn more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4 md:w-1/2">
                <div className="h-full rounded-xl shadow-cla-violate bg-gradient-to-r from-pink-50 to-red-50 overflow-hidden">
                  <img
                    className="lg:h-48 md:h-36 w-full object-cover object-center scale-110 transition-all duration-400 hover:scale-100"
                    src={car}
                    alt="blog"
                  />
                  <div className="p-6">
                    <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                      Marketing Pitch
                    </h2>
                    <h1 className="title-font text-lg font-medium text-gray-600 mb-3">
                      Roadside Rescue Marketing Pitch
                    </h1>
                    <p className="leading-relaxed mb-3">
                      Roadside Rescue is a project that I worked on for my
                      Marketing Principles course. I collaborated with group
                      members to invent a product or service that would be
                      presented to investors.
                    </p>
                    <div className="flex items-center flex-wrap ">
                      <Link
                        to="/roadside"
                        className="text-white font-medium text-sm bg-gradient-to-r from-fuchsia-300 to-pink-400 hover:scale-105  shadow-cla-blue px-5 py-2 rounded-3xl"
                      >
                        Learn more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4 md:w-1/2">
                <div className="h-full rounded-xl shadow-cla-pink bg-gradient-to-r from-fuchsia-50 to-pink-50 overflow-hidden">
                  <img
                    className="lg:h-48 md:h-36 w-full object-cover object-center scale-110 transition-all duration-400 hover:scale-100"
                    src={airfryer}
                    alt="blog"
                  />
                  <div className="p-6">
                    <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                      Writing Sample
                    </h2>
                    <h1 className="title-font text-lg font-medium text-gray-600 mb-3">
                      The Elements of Value: Air Fryer
                    </h1>
                    <p className="leading-relaxed mb-3">
                      Great products or services target consumer needs under the
                      Elements of Value pyramind. In the paper, I discussed how
                      the air fryer targets many consumer needs and
                      differentiates itself compared to many other kitchen
                      appliances on the market.
                    </p>
                    <div className="flex items-center flex-wrap ">
                      <Link
                        to="/airfryer"
                        className="text-white font-medium text-sm bg-gradient-to-r from-fuchsia-300 to-pink-400 hover:scale-105  shadow-cla-blue px-5 py-2 rounded-3xl"
                      >
                        Learn more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4 md:w-1/2">
                <div className="h-full rounded-xl shadow-cla-pink bg-gradient-to-r from-fuchsia-50 to-pink-50 overflow-hidden">
                  <img
                    className="lg:h-48 md:h-36 w-full object-cover object-center scale-110 transition-all duration-400 hover:scale-100"
                    src={book}
                    alt="blog"
                  />
                  <div className="p-6">
                    <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                      Product Market Fit
                    </h2>
                    <h1 className="title-font text-lg font-medium text-gray-600 mb-3">
                      LoveNote - User Experience Research
                    </h1>
                    <p className="leading-relaxed mb-3">
                      LoveNote is an education startup app working towards
                      finding easier ways for users to learn languages. I
                      aggregated information for translation between English,
                      Spanish, and Twi. I also conducted research and data
                      analysis to determine how people learn and process new
                      languages.
                    </p>
                    <div className="flex items-center flex-wrap ">
                      <Link
                        to="/lovenote"
                        className="text-white font-medium text-sm bg-gradient-to-r from-fuchsia-300 to-pink-400 hover:scale-105  shadow-cla-blue px-5 py-2 rounded-3xl"
                      >
                        Learn more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="grid max-w-screen-lg px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <div className="relative inline-flex overflow-hidden w-80 h-100">
              <img
                src={portfolio}
                alt="Ana Diaz-Ramos"
                className="self-center drop-shadow-2xl object-cover"
              />
            </div>
          </div>
          <div className="mr-auto place-self-center mt-10 lg:col-span-7">
            <h1 className="text-5xl font-display font-medium tracking-tight text-dark sm:text-5xl md:text-5xl">
              Ana Diaz-Ramos
            </h1>
            <p className="max-w-2xl leading-relaxed mb-6 mt-2 font-light text-gray-800 lg:mb-8 md:text-lg lg:text-lg">
              A passionate marketer with the goal to help bring products and
              services to those in need and drive business impact.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Landing;
