import React, { useState } from "react";
import { Link } from "react-router-dom";
import portfolio from "./img/porfolio.svg";
import Footer from "./Footer";
import piano from "./img/piano.jpeg";

function Quartal() {
  return (
    <div className="flex flex-col w-full min-h-screen overflow-hidden font-sans">
      <nav
        className={
          "sticky flex justify-center align-middle w-full py-3 lg:py-5 mx-auto shadow-xl shadow-gray-100"
        }
      >
        <Link to="/">
          <div className="relative  flex items-center text-lg font-serif font-bold py-2">
            Ana D.
          </div>
        </Link>
      </nav>
      <div className="flex sm:px-4 lg:px-44 md:px-20 px-10 flex-col py-20 h-full w-full overflow-hidden text-left grow">
        <h1 className="text-5xl font-medium text-dark sm:text-5xl md:text-5xl">
          Quartal Digital Marketing Development
        </h1>
        <p className=" leading-relaxed mb-1 mt-6 font-light text-gray-800  md:text-lg lg:text-lg">
          <b className="font-bold">Description: </b> Quartal is a music
          education startup company that makes music easier than ever to learn
          and teach music. I work in the area of digital marketing development
          while using growth marketing strategies to promote brand awareness on
          social media and turn viewership into profit.
        </p>

        <p className=" leading-relaxed mb-6 mt-2 font-light text-gray-800 lg:mb-8 md:text-lg lg:text-lg">
          <b className="font-bold">Role: </b> Digital marketing development:
          Social Media, SEO, Copywriting, Advertisements
        </p>

        <p className=" leading-relaxed mb-6 mt-2 font-light text-gray-800 lg:mb-8 md:text-lg lg:text-lg">
          <b className="font-bold">Website Link: </b>{" "}
          <a
            className="text-blue-600"
            target="_blank"
            href="https://quartal.net"
          >
            https://quartal.net
          </a>
        </p>

        <img src={piano} alt="header image" className="w-full mb-10" />

        <hr></hr>

        <p className=" leading-relaxed mb-1 mt-6 font-light text-gray-800  md:text-lg lg:text-lg">
          Quartal is a music education startup company that makes music easier
          than ever to learn and teach music. I work in the area of digital
          marketing development while using growth marketing strategies to
          promote brand awareness on social media and turn viewership into
          profit.
        </p>
        <p className=" leading-relaxed mb-1 mt-6 font-light text-gray-800  md:text-lg lg:text-lg">
          I have created content to upload onto Quartal’s Youtube, Tiktok,
          Instagram, and Facebook pages. I have done social media copywriting
          and crafted messages that would help boost customer engagement. These
          efforts have resulted in 20k+ impressions in a period of two weeks.
          For SEO, I created personal content creation blogs to drive back
          links. Additionally, I created Google, Facebook, and Instagram
          advertisement campaigns.
        </p>

        <div className="grid max-w-screen-lg px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <div className="relative inline-flex overflow-hidden w-80 h-100">
              <img
                src={portfolio}
                alt="Ana Diaz-Ramos"
                className="self-center drop-shadow-2xl object-cover"
              />
            </div>
          </div>
          <div className="mr-auto place-self-center mt-10 lg:col-span-7">
            <h1 className="text-5xl font-display font-medium tracking-tight text-dark sm:text-5xl md:text-5xl">
              Ana Diaz-Ramos
            </h1>
            <p className="max-w-2xl leading-relaxed mb-6 mt-2 font-light text-gray-800 lg:mb-8 md:text-lg lg:text-lg">
              A passionate marketer with the goal to help bring products and
              services to those in need and drive business impact.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Quartal;
