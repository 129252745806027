import React, { useState, useEffect } from "react";

const Footer = () => {
  return (
    <div className="w-full">
      <footer className="p-4 bg-white sm:p-6 dark:bg-neutral-900">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <a href="#" target="_blank" className="flex items-center">
              <span className="self-center text-xl font-semibold font-display whitespace-nowrap dark:text-white">
                Ana Diaz-Ramos
              </span>
            </a>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-8 sm:grid-cols-2">
            <div>
              <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Contact
              </h3>
              <ul>
                <li className="mb-4">
                  <a
                    href="/img/Ana.DiazRamos.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-600 hover:underline dark:text-gray-400"
                  >
                    Resume
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="https://www.linkedin.com/in/ana-diaz-ramos-44a295212/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-600 hover:underline dark:text-gray-400"
                  >
                    LinkedIn
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="mailto: adiazramos02@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-600 hover:underline dark:text-gray-400"
                  >
                    Email
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-g-200 sm:mx-auto dark:border-black lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2022 Ana Diaz-Ramos. All Rights Reserved.
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
