import React, { useState } from "react";
import { Link } from "react-router-dom";
import portfolio from "./img/porfolio.svg";
import Footer from "./Footer";
import car from "./img/car.jpeg";

function RoadSide() {
  return (
    <div className="flex flex-col w-full min-h-screen overflow-hidden font-sans">
      <nav
        className={
          "sticky flex justify-center align-middle w-full py-3 lg:py-5 mx-auto shadow-xl shadow-gray-100"
        }
      >
        <Link to="/">
          <div className="relative  flex items-center text-lg font-serif font-bold py-2">
            Ana D.
          </div>
        </Link>
      </nav>
      <div className="flex sm:px-4 lg:px-44 md:px-20 px-10 flex-col py-20 h-full w-full overflow-hidden text-left grow">
        <h1 className="text-5xl font-medium text-dark sm:text-5xl md:text-5xl">
          Roadside Rescue Marketing Pitch
        </h1>
        <p className=" leading-relaxed mb-1 mt-6 font-light text-gray-800  md:text-lg lg:text-lg">
          <b className="font-bold">Description: </b> Roadside Rescue is a
          project that I worked on for my Marketing Principles course. I
          collaborated with group members to invent a product or service that
          would be presented to investors.
        </p>

        <p className=" leading-relaxed mb-6 mt-2 font-light text-gray-800 lg:mb-8 md:text-lg lg:text-lg">
          <b className="font-bold">Role: </b> Marketing research analyst, movie
          editor, logo designer, writer, and presenter.
        </p>

        <p className=" leading-relaxed mb-6 mt-2 font-light text-gray-800 lg:mb-8 md:text-lg lg:text-lg">
          <b className="font-bold">Youtbube Link: </b>{" "}
          <a
            className="text-blue-600"
            target="_blank"
            href="https://www.youtube.com/watch?v=TEoDhaseG9w"
          >
            https://www.youtube.com/watch?v=TEoDhaseG9w
          </a>
        </p>

        <img src={car} alt="header image" className="w-full mb-10" />

        <hr></hr>

        <p className=" leading-relaxed mb-1 mt-6 font-light text-gray-800  md:text-lg lg:text-lg">
          Roadside Rescue is a project that I worked on for my Marketing
          Principles course. I collaborated with group members to invent a
          product or service that would be presented to investors. Our idea was
          to develop a roadside assistance app that was free and quick to
          install. This feature would make it attractive to potential users
          since many roadside assistance companies are expensive, some might not
          have a membership, and it might take a while to get the help needed.
          Another feature of the app is that it would be crowdsourced. This
          would open opportunities for handyman on the road to earn quick cash
          as they could use their skills on those who need roadside assistance.
        </p>
        <p className=" leading-relaxed mb-1 mt-6 font-light text-gray-800  md:text-lg lg:text-lg">
          In the video presentation, we included a mission statement for
          Roadside Rescue which was, “Responsive, reliable, professional,
          efficient, and convenient service for drivers in need of car service
          and an opportunity for those with the ability to service cars to earn
          money.” We then explored the fundamental marketing elements which
          included product, price, place, and promotion from the “4 P’s of
          Marketing” as well as a SWOT analysis, market segmentation variables,
          and KPI’s.
        </p>
        <p className=" leading-relaxed mb-1 mt-6 font-light text-gray-800  md:text-lg lg:text-lg">
          For the marketing research, my group members and I used Google
          Scholar, Google Trends, and EBSCO’s library database. In terms of
          designing the logo, I used Canva and Figma. For the video
          presentation, I created it with iMovie.
        </p>

        <div className="grid max-w-screen-lg px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <div className="relative inline-flex overflow-hidden w-80 h-100">
              <img
                src={portfolio}
                alt="Ana Diaz-Ramos"
                className="self-center drop-shadow-2xl object-cover"
              />
            </div>
          </div>
          <div className="mr-auto place-self-center mt-10 lg:col-span-7">
            <h1 className="text-5xl font-display font-medium tracking-tight text-dark sm:text-5xl md:text-5xl">
              Ana Diaz-Ramos
            </h1>
            <p className="max-w-2xl leading-relaxed mb-6 mt-2 font-light text-gray-800 lg:mb-8 md:text-lg lg:text-lg">
              A passionate marketer with the goal to help bring products and
              services to those in need and drive business impact.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default RoadSide;
